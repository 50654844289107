import React, { useState, useRef } from "react";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface DropdownProps {
  defaultText: string;
  selectedOption?: string;
  allOptions: string[];
  validOptions?: string[];
  onSelect: (option: string) => void;
}

export default function Dropdown({
  defaultText,
  selectedOption,
  allOptions,
  validOptions,
  onSelect,
}: DropdownProps) {
  const [showMenu, setShowMenu] = useState(false);

  const handleSelectOption = (option: string) => {
    setShowMenu(!showMenu);
    onSelect(option);
  };

  const ref = useRef();
  useOutsideClick(ref, () => setShowMenu(false));

  const effectiveValidOptions = validOptions || allOptions;

  return (
    <div ref={ref as any} className={`relative inline-block text-left w-full`}>
      <button
        type="button"
        className={`inline-flex w-full h-8 justify-between items-center px-2 border-black border-2 bg-white hover:bg-gray-50`}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={() => setShowMenu(!showMenu)}
      >
        <span
          className={`font-semibold truncate ${
            selectedOption ? "text-gray-700" : "text-gray-400"
          }`}
        >
          {selectedOption ?? defaultText}
        </span>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={`text-sm text-gray-700 transition-transform transform ${
            showMenu ? "rotate-180" : ""
          }`}
        />
      </button>
      {showMenu ? (
        <div className="w-full bg-white focus:outline-none absolute z-10 pb-4">
          <div className="border-x-2 border-b-2 border-black">
            {allOptions.map((option, idx) => (
              <button
                key={idx}
                className={`text-left block w-full px-2 h-8 text-base ${
                  effectiveValidOptions.includes(option)
                    ? "text-gray-700 hover:bg-gray-200 hover:text-gray-900"
                    : "text-gray-400 cursor-not-allowed"
                } ${
                  idx === allOptions.length - 1 ? "" : "border-b border-black"
                } truncate`}
                role="menuitem"
                onClick={() =>
                  effectiveValidOptions.includes(option) &&
                  handleSelectOption(option)
                }
                disabled={!effectiveValidOptions.includes(option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
