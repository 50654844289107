import { useEffect } from "react";
import ReactGA from "react-ga4";

export default function AboutPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/about",
      title: "About Page",
    });
  }, []);

  return <h1>About Page</h1>;
}
