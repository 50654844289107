import React from "react";
import AxisSelection from "./axes/AxisSelection";
import TraceSelection from "./traces/TraceSelection";
import FilterSelection from "./filters/FilterSelection";
import { plottedAxesAtom } from "../../../common/store";
import { useAtomValue } from "jotai";
import ReactGA from "react-ga4";

interface LeftSidebarProps {
  handleRefreshPlot: () => void;
}

export default function LeftSidebar({ handleRefreshPlot }: LeftSidebarProps) {
  const plottedAxes = useAtomValue(plottedAxesAtom);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto">
        <AxisSelection />
        <TraceSelection />
        <FilterSelection />
      </div>
      <div className="mt-auto">
        <div className="border-t-2 border-black p-4">
          <button
            className="w-full h-12 border-2 border-black px-4 flex items-center justify-center shadow-lg bg-founders-rock hover:bg-founders-rock-600 text-white font-bold"
            onClick={() => {
              ReactGA.event("refresh_plot");
              handleRefreshPlot();
            }}
          >
            {plottedAxes.length === 2 ? "Update Plot" : "Plot"}
          </button>
        </div>
        <div className="border-t-2 border-black p-4">
          <span>Copyright © 2024. Made with 🐻 at UC Berkeley.</span>
        </div>
      </div>
    </div>
  );
}
