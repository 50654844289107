import React from "react";
import Dropdown from "../../../Dropdown";
import { COLUMNS } from "../../../../utils/octopart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useAtom } from "jotai";
import { selectedFiltersAtom } from "../../../../common/store";
import { FilterKind } from "../../../../utils/types";
import { useAllAttributes } from "../../../../hooks/validate";
import Tooltip from "../../../Tooltip";
import ReactGA from "react-ga4";

const onKeyDownValidator = (event: React.KeyboardEvent<HTMLInputElement>) => {
  // Allow positive and negative numbers, decimal points, and certain navigation keys.
  // Since we later convert the input value from `string` to `number`, we allow 'e' as
  // well. See this comprehensive table on StackOverflow for how strings are converted
  // to numbers after parsing: https://stackoverflow.com/a/17106702
  if (
    !/[0-9.e-]/.test(event.key) &&
    ![
      "Backspace",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Enter",
    ].includes(event.key)
  ) {
    event.preventDefault();
  } else if (event.key === "." && event.currentTarget.value.includes(".")) {
    // Prevent entering more than one decimal point.
    event.preventDefault();
  }
};

export default function FilterSelection() {
  const [selectedFilters, setSelectedFilters] = useAtom(selectedFiltersAtom);
  const allAttributes = useAllAttributes();

  const handleAddFilter = () => {
    ReactGA.event("add_filter_selection");
    let filter = {
      kind: FilterKind.Numerical,
    };
    setSelectedFilters((prev) => [...prev, filter]);
  };

  const handleRemoveFilter = (filter_idx: number) => {
    ReactGA.event("remove_filter_selection");
    setSelectedFilters((prev) => prev.filter((_, idx) => idx !== filter_idx));
  };

  const handleUpdateFilter = (
    filter_idx: number,
    kind?: FilterKind,
    abbreviation?: string,
    min_raw?: string,
    max_raw?: string
  ) => {
    var currentFilter = selectedFilters.find((f, idx) => idx === filter_idx);
    if (!currentFilter) return;

    console.log("MIN/MAX RAW: ", min_raw, max_raw);

    const determineValue = (
      defaultValue: number,
      rawValue?: string,
      currentValue?: number
    ) => {
      // Explicitly check for undefined for `min` and `max`, since 0 is a valid value but evaluates to false.
      if (
        (rawValue === undefined && currentValue === undefined) ||
        rawValue === ""
      ) {
        return defaultValue;
      } else if (rawValue === undefined && currentValue !== undefined) {
        return currentValue;
      } else if (rawValue !== undefined) {
        return Number(rawValue);
      }
    };

    const min = determineValue(
      Number.MIN_SAFE_INTEGER,
      min_raw,
      currentFilter.min
    );
    const max = determineValue(
      Number.MAX_SAFE_INTEGER,
      max_raw,
      currentFilter.max
    );

    let attribute = COLUMNS.find((c) => c.abbreviation === abbreviation);
    let filter = {
      kind: kind ?? currentFilter.kind,
      name: abbreviation ? attribute?.name : currentFilter.name,
      abbreviation: abbreviation ?? currentFilter.abbreviation,
      id: abbreviation ? attribute?.column : currentFilter.id,
      min,
      minHumanString: min_raw,
      max,
      maxHumanString: max_raw,
    };
    const updatedFilters = [...selectedFilters]; // Trigger re-render
    updatedFilters[filter_idx] = filter;
    setSelectedFilters(updatedFilters);
  };

  return (
    <div className="border-b-2 border-black">
      <div className="flex justify-between items-center px-8 pt-4">
        <div className="w-1/2">
          <p className="text-lg font-semibold">Filters</p>
        </div>
        {selectedFilters.length > 0 ? (
          <>
            <div className="w-1/6">
              <p className="text-lg text-gray-400 font-regular">Min</p>
            </div>
            <div className="w-1/6">
              <p className="text-lg text-gray-400 font-regular">Max</p>
            </div>
          </>
        ) : null}
        <Tooltip text="Add">
          <button
            className="w-8 h-8 flex items-center justify-center p-2 border-2 border-black hover:bg-gray-200"
            onClick={handleAddFilter}
          >
            <FontAwesomeIcon icon={faPlus} className="text-sm" />
          </button>
        </Tooltip>
      </div>
      <div className="px-8 pt-4">
        {selectedFilters.map((filter, idx) => {
          return (
            <div className="flex items-center justify-between pb-4">
              <div className="w-1/2">
                <Dropdown
                  defaultText={"Filter"}
                  selectedOption={filter.abbreviation}
                  allOptions={allAttributes}
                  onSelect={(filter) =>
                    handleUpdateFilter(
                      idx,
                      FilterKind.Numerical,
                      filter,
                      undefined,
                      undefined
                    )
                  }
                />
              </div>
              <input
                key={`min-${filter.abbreviation}`}
                type="text"
                placeholder="-Inf"
                className="w-1/6 h-8 p-2 border-2 border-black"
                onKeyDown={onKeyDownValidator}
                value={filter.minHumanString}
                onChange={(e) =>
                  handleUpdateFilter(
                    idx,
                    FilterKind.Numerical,
                    undefined,
                    e.target.value,
                    undefined
                  )
                }
              />
              <input
                key={`max-${filter.abbreviation}`}
                type="text"
                placeholder="+Inf"
                className="w-1/6 h-8 p-2 border-2 border-black"
                onKeyDown={onKeyDownValidator}
                value={filter.maxHumanString}
                onChange={(e) =>
                  handleUpdateFilter(
                    idx,
                    FilterKind.Numerical,
                    undefined,
                    undefined,
                    e.target.value
                  )
                }
              />
              <Tooltip text="Remove">
                <button
                  className="w-8 h-8 flex items-center justify-center p-2 border-2 border-black hover:bg-gray-200"
                  onClick={() => handleRemoveFilter(idx)}
                >
                  <FontAwesomeIcon icon={faMinus} className="text-sm" />
                </button>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
}
