import { useEffect } from "react";
import ReactGA from "react-ga4";

export default function ContactPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/contact",
      title: "Contact Page",
    });
  }, []);

  return (
    <div className="w-full p-8 pt-12 flex flex-col items-center justify-center">
      <div className="text-base mb-2">
        <span className="pr-4">Nathan Brooks:</span>
        <a
          href="mailto:brooksnc@rose-hulman.edu"
          className="text-founders-rock hover:underline"
        >
          brooksnc@rose-hulman.edu
        </a>
      </div>
      <div className="text-base">
        <span className="pr-4">Shalin Shah:</span>
        <a
          href="mailto:shalinvs@gmail.com"
          className="text-founders-rock hover:underline"
        >
          shalinvs@gmail.com
        </a>
      </div>
    </div>
  );
}
