import Form from "../interface/Form";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";

export default function HomePage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home Page",
    });
  }, []);

  return <Form />;
}
