import React, { useEffect } from "react";
import HomePage from "../pages/HomePage";
import ContactPage from "../pages/ContactPage";
import AboutPage from "../pages/AboutPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import ReactGA from "react-ga4";

const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-ZKWMQ6Y9GD";

function App() {
  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
  }, []);

  return (
    <div className="pt-24 min-h-screen">
      <BrowserRouter>
        <Navbar />
        <div className="h-full">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
