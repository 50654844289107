import React from "react";
import Dropdown from "../../../Dropdown";
import { AxisKind } from "../../../../utils/types";
import { selectedAxesAtom } from "../../../../common/store";
import { useAtom } from "jotai";
import {
  useAllAttributes,
  useValidatedAttributes,
} from "../../../../hooks/validate";
import { COLUMNS } from "../../../../utils/octopart";

export default function AxisSelection() {
  const [selectedAxes, setSelectedAxes] = useAtom(selectedAxesAtom);
  const allAttributes = useAllAttributes();
  const validAttributes = useValidatedAttributes();

  const handleUpdateAxis = (
    axis_idx: number,
    abbreviation: string,
    kind: AxisKind
  ) => {
    let name = COLUMNS.find((c) => c.abbreviation === abbreviation)?.name;
    let axis = {
      name,
      abbreviation,
      kind,
    };
    const updatedAxes = [...selectedAxes]; // Trigger re-render
    updatedAxes[axis_idx] = axis;
    setSelectedAxes(updatedAxes);
  };

  return (
    <div className="border-b-2 border-black w-full">
      <div className="px-8 pt-4">
        <p className="text-lg font-semibold">Axes</p>
      </div>
      <div className="flex items-center space-x-4 px-8 py-4">
        <div className="w-1/2 bg-black">
          <Dropdown
            defaultText={"X-Axis"}
            selectedOption={selectedAxes?.[0]?.abbreviation}
            allOptions={allAttributes}
            validOptions={validAttributes}
            onSelect={(abbreviation) => {
              handleUpdateAxis(0, abbreviation, AxisKind.X);
            }}
          />
        </div>
        <div className="w-1/2">
          <Dropdown
            defaultText={"Y-Axis"}
            selectedOption={selectedAxes?.[1]?.abbreviation}
            allOptions={allAttributes}
            validOptions={validAttributes}
            onSelect={(abbreviation) => {
              handleUpdateAxis(1, abbreviation, AxisKind.Y);
            }}
          />
        </div>
      </div>
    </div>
  );
}
