import {
  plottedAxesAtom,
  plottedTracesAtom,
  selectedAxesAtom,
  selectedTracesAtom,
} from "../common/store";
import { useAtomValue } from "jotai";
import { COLUMNS } from "../utils/octopart";
import { ColumnType } from "../proto/ts/componet.metadata";
import { PartialAxis, PartialTrace } from "../utils/types";

export function useAllAttributes() {
  let allAttributes = COLUMNS.filter(
    (c) => c.type === ColumnType.Attribute && c.included === true
  ).map((c) => c.abbreviation!);
  return allAttributes!;
}

export function useAllComponents() {
  let allComponents = COLUMNS.filter(
    (c) => c.type === ColumnType.Category && c.included === true
  ).map((c) => c.abbreviation!);
  return allComponents!;
}

/**
 * Since certain component and attribute combinations don't make sense to plot, this function
 * filters attributes based on what is either selected to be plotted, or already plotted to
 * prevent the user from selecting options that don't make sense.
 *
 * @returns {string[]} Array of column abbreviations or names validated by current axis settings.
 */
export function useValidatedAttributes() {
  const selectedAxes = useAtomValue(selectedAxesAtom);
  const selectedTraces = useAtomValue(selectedTracesAtom);
  const plottedTraces = useAtomValue(plottedTracesAtom);

  function isAttributeValidForAllTraces(
    traces: PartialTrace[],
    attribute: string
  ) {
    let allTracesValid = traces
      .map((trace) => {
        let column = COLUMNS.find((column) => column.name === trace.name);
        return column && column?.valid.includes(attribute);
      })
      .every((c) => c);
    return allTracesValid;
  }

  // 1. Start with all attributes as valid.
  let validColumns = COLUMNS.filter(
    (c) => c.type === ColumnType.Attribute && c.included === true
  );

  // 2. Filter out any attribute that's currently selected, for example to avoid issues
  //    like "Price vs. Price"
  validColumns = validColumns.filter(
    (c) => !selectedAxes.map((a) => a.name).includes(c.name)
  );

  // 3. Filter out any attribute that isn't valid for the current traces that are a part
  // of the plot.
  if (plottedTraces.length > 0) {
    validColumns = validColumns.filter((maybeValidColumn) => {
      let shortname = maybeValidColumn.column;
      return isAttributeValidForAllTraces(plottedTraces, shortname);
    });
  }

  // 4. Filter out any attribute that isn't valid for any new/updated traces the user has
  //    selected to include.
  if (selectedTraces.length > 0 && selectedTraces.some((t) => t.name)) {
    validColumns = validColumns.filter((maybeValidColumn) => {
      let shortname = maybeValidColumn.column;
      return isAttributeValidForAllTraces(selectedTraces, shortname);
    });
  }

  return validColumns.map((c) => c.abbreviation!);
}

/**
 * Since certain component and attribute combinations don't make sense to plot, this function
 * filters components based on what is either selected to be plotted, or already plotted to
 * prevent the user from selecting options that don't make sense.
 *
 * @returns {string[]} Array of column names validated by the current trace settings.
 */
export function useValidatedComponents() {
  const selectedAxes = useAtomValue(selectedAxesAtom);
  const plottedAxes = useAtomValue(plottedAxesAtom);

  function getShortnames(axes: PartialAxis[]) {
    return axes.map(
      (axis) => COLUMNS.find((column) => column.name === axis.name)?.column
    );
  }

  // 1. Start with all columns as valid.
  let validColumns = COLUMNS.filter(
    (c) => c.type === ColumnType.Category && c.included === true
  );

  // 2. Filter using the axes that are part of the current plot. If the user has updated
  //    their selected axes, then use those for filtering instead.
  validColumns = validColumns.filter((maybeValidColumn) => {
    if (selectedAxes.length === 2 || plottedAxes.length === 2) {
      let axesToUse = selectedAxes.length === 2 ? selectedAxes : plottedAxes;
      let shortnames = getShortnames(axesToUse);
      return shortnames.every(
        (shortname) => shortname && maybeValidColumn.valid.includes(shortname)
      );
    }
    return true;
  });

  return validColumns.map((c) => c.abbreviation!);
}
