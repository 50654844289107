import React from "react";
import AxisItem from "./AxisItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightArrowLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  plottedAxesAtom,
  plottedFiltersAtom,
  plottedParetoQuadrantsAtom,
  plottedPointsWithXAtom,
  plottedTracesAtom,
  selectedAxesAtom,
} from "../../../../common/store";
import { useAtom, useSetAtom } from "jotai";
import { SelectionConstants } from "../../../plot/consts";
import { AxisKind } from "../../../../utils/types";
import Tooltip from "../../../Tooltip";
import ReactGA from "react-ga4";

export default function AxisSettings() {
  const [plottedAxes, setPlottedAxes] = useAtom(plottedAxesAtom);
  const setPlottedPointsWithX = useSetAtom(plottedPointsWithXAtom);
  const setPlottedParetoQuadrants = useSetAtom(plottedParetoQuadrantsAtom);
  const setPlottedTraces = useSetAtom(plottedTracesAtom);
  const setPlottedFilters = useSetAtom(plottedFiltersAtom);

  const setSelectedAxes = useSetAtom(selectedAxesAtom);

  const swapAxes = () => {
    ReactGA.event("swap_axes");
    setPlottedAxes((prev) => {
      let rev = prev.reverse();
      rev.forEach(
        (axis) =>
          (axis.kind = axis.kind === AxisKind.X ? AxisKind.Y : AxisKind.X)
      );
      setSelectedAxes([...rev]);
      return [...rev];
    });
  };

  const removeAxes = () => {
    ReactGA.event("remove_axes");
    // Reset all plotted state.
    setPlottedAxes([]);
    setPlottedTraces([]);
    setPlottedPointsWithX([]);
    setPlottedFilters([]);
    setPlottedParetoQuadrants(SelectionConstants.paretoQuadrants);
  };

  return (
    <div className="border-b-2 border-black">
      <div className="px-8 pt-4">
        <p className="text-lg font-semibold">Axes</p>
      </div>
      <div className="px-8 pt-4">
        {plottedAxes.length === 2 ? (
          <div className="flex justify-between item-center pb-4 space-x-2">
            <AxisItem axis={plottedAxes[0]} />
            <AxisItem axis={plottedAxes[1]} />
            <Tooltip text="Swap">
              <button
                className="w-8 h-8 flex items-center justify-center p-2 border-2 border-black hover:bg-gray-200"
                onClick={swapAxes}
              >
                <FontAwesomeIcon
                  icon={faArrowRightArrowLeft}
                  className="text-sm"
                />
              </button>
            </Tooltip>
            <Tooltip text="Reset">
              <button
                className="w-8 h-8 flex items-center justify-center p-2 border-2 border-golden-gate hover:bg-gray-200"
                onClick={removeAxes}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  className="text-base text-golden-gate"
                />
              </button>
            </Tooltip>
          </div>
        ) : null}
      </div>
    </div>
  );
}
