import React from "react";
import Dropdown from "../../../Dropdown";
import { DEFAULT_YEAR, YEARS } from "../../../../utils/consts";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectedTracesAtom } from "../../../../common/store";
import { useAtom } from "jotai";
import {
  useAllComponents,
  useValidatedComponents,
} from "../../../../hooks/validate";
import { COLUMNS } from "../../../../utils/octopart";
import Tooltip from "../../../Tooltip";
import ReactGA from "react-ga4";

export default function TraceSelection() {
  const [selectedTraces, setSelectedTraces] = useAtom(selectedTracesAtom);
  const allComponents = useAllComponents();
  const validComponents = useValidatedComponents();

  const handleAddTrace = () => {
    ReactGA.event("add_trace_selection");
    let trace = {
      year: DEFAULT_YEAR,
    };
    setSelectedTraces((prev) => [...prev, trace]);
  };

  const handleRemoveTrace = (trace_idx: number) => {
    ReactGA.event("remove_trace_selection");
    setSelectedTraces((prev) => prev.filter((_, idx) => idx !== trace_idx));
  };

  const handleUpdateTrace = (
    trace_idx: number,
    abbreviation?: string,
    year?: string
  ) => {
    var currentTrace = selectedTraces.find((_, idx) => idx === trace_idx);
    if (!currentTrace) return;

    let trace = {
      abbreviation: abbreviation ?? currentTrace.abbreviation,
      name: abbreviation
        ? COLUMNS.find((c) => c.abbreviation === abbreviation)?.name
        : currentTrace.name,
      year: year ?? currentTrace.year,
      visible: true,
    };
    const updatedTraces = [...selectedTraces]; // Trigger re-render
    updatedTraces[trace_idx] = trace;
    setSelectedTraces(updatedTraces);
  };

  return (
    <div className="border-b-2 border-black">
      <div className="flex justify-between items-center px-8 pt-4">
        <p className="text-lg font-semibold">Traces</p>
        <Tooltip text="Add">
          <button
            className="w-8 h-8 flex items-center justify-center p-2 border-2 border-black hover:bg-gray-200"
            onClick={handleAddTrace}
          >
            <FontAwesomeIcon icon={faPlus} className="text-sm" />
          </button>
        </Tooltip>
      </div>

      <div className="px-8 pt-4">
        {selectedTraces.map((trace, idx) => {
          return (
            <div className="flex items-center justify-between pb-4">
              <div className="w-1/2">
                <Dropdown
                  defaultText={"Component"}
                  selectedOption={trace.abbreviation}
                  allOptions={allComponents}
                  validOptions={validComponents}
                  onSelect={(category) =>
                    handleUpdateTrace(idx, category, undefined)
                  }
                />
              </div>
              <div className="w-1/3">
                <Dropdown
                  defaultText={"Year"}
                  selectedOption={trace.year}
                  allOptions={YEARS}
                  validOptions={YEARS}
                  onSelect={(year) => handleUpdateTrace(idx, undefined, year)}
                />
              </div>
              <Tooltip text="Remove">
                <button
                  className="w-8 h-8 flex items-center justify-center p-2 border-2 border-black hover:bg-gray-200"
                  onClick={() => handleRemoveTrace(idx)}
                >
                  <FontAwesomeIcon icon={faMinus} className="text-sm" />
                </button>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
}
