import React from "react";
import { Point, RequiredTrace } from "../../../utils/types";
import TraceSettings from "./traces/TraceSettings";
import PointSettings from "./points/PointSettings";
import AxisSettings from "./axes/AxisSettings";
import FilterSettings from "./filters/FilterSettings";
import ParetoSettings from "./pareto/ParetoSettings";

interface RightSidebarProps {
  handleRemoveTrace: (trace: RequiredTrace) => void;
  handleRemovePoint: (point: Point, index: number) => void;
}

export default function RightSidebar({
  handleRemoveTrace,
  handleRemovePoint,
}: RightSidebarProps) {
  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto">
        <ParetoSettings />
        <AxisSettings />
        <TraceSettings handleRemoveTrace={handleRemoveTrace} />
        <FilterSettings />
        <PointSettings handleRemovePoint={handleRemovePoint} />
      </div>
    </div>
  );
}
