import React from "react";
import { Point } from "../../../../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faMinus } from "@fortawesome/free-solid-svg-icons";
import ExportButton from "../../../ExportButton";
import PointItem from "./PointItem";
import { useAtomValue } from "jotai";
import { plottedPointsWithXAtom } from "../../../../common/store";
import Tooltip from "../../../Tooltip";
import ReactGA from "react-ga4";

interface PointSettingsProps {
  handleRemovePoint: (point: Point, index: number) => void;
}

export default function PointSettings({
  handleRemovePoint,
}: PointSettingsProps) {
  const plottedPointsWithX = useAtomValue(plottedPointsWithXAtom);

  const trackOutboundLink = (
    event: React.MouseEvent<HTMLAnchorElement>,
    url: string
  ) => {
    event.preventDefault(); // Prevent default anchor click behavior

    ReactGA.event("visit_point_url", {
      link_url: url,
    });

    // Open the link in a new tab after a slight delay to ensure the event is sent
    setTimeout(() => {
      window.open(url, "_blank", "noopener,noreferrer");
    }, 100);
  };

  return (
    <div className="border-b-2 border-black">
      <div className="px-8 pt-4">
        <p className="text-lg font-semibold">Selected Points</p>
      </div>
      <div className="px-8 pt-4">
        {plottedPointsWithX.map((point, i) => {
          return (
            <div className="flex justify-between item-center w-full pb-4 space-x-2">
              <PointItem point={point} />
              <Tooltip text="Visit">
                <a
                  className="w-8 h-8 flex items-center justify-center p-2 border-2 border-black hover:bg-gray-200"
                  href={point.link}
                  onClick={(e) => trackOutboundLink(e, point.link)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLink} className="text-sm" />
                </a>
              </Tooltip>
              <Tooltip text="Remove">
                <button
                  className="w-8 h-8 flex items-center justify-center p-2 border-2 border-black hover:bg-gray-200"
                  onClick={() => {
                    ReactGA.event("remove_point");
                    handleRemovePoint(point, i);
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} className="text-sm" />
                </button>
              </Tooltip>
            </div>
          );
        })}
      </div>
      {plottedPointsWithX.length > 0 ? (
        <div className="px-8 py-4">
          <ExportButton points={plottedPointsWithX} />
        </div>
      ) : null}
    </div>
  );
}
