import React from "react";

import { AxisKind, RequiredAxis } from "../../../../utils/types";
import xIcon from "../../../../assets/x-axis.png";
import yIcon from "../../../../assets/y-axis.png";

interface AxisItemProps {
  axis: RequiredAxis;
}

export default function AxisItem({ axis }: AxisItemProps) {
  return (
    <div className="flex w-full min-w-0 items-center border-2 border-black">
      <span className="flex items-center justify-center border-r-2 border-black h-full px-1">
        <img
          src={axis.kind === AxisKind.X ? xIcon : yIcon}
          alt={axis.kind === AxisKind.X ? "X Axis" : "Y Axis"}
          className="w-6 h-6"
        />
      </span>
      <span className="text-base px-2 truncate">{axis.abbreviation}</span>
    </div>
  );
}
