import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { plottedParetoQuadrantsAtom } from "../../../../common/store";
import { useAtom } from "jotai";
import ReactGA from "react-ga4";

export default function ParetoSettings() {
  const [plottedParetoQuadrants, setPlottedParetoQuadrants] = useAtom(
    plottedParetoQuadrantsAtom
  );

  const handleToggleQuadrant = (quadrants: boolean) => {
    setPlottedParetoQuadrants((prev) => ({
      ...prev,
      1: quadrants,
      2: quadrants,
      3: quadrants,
      4: quadrants,
    }));
  };
  const allSelected = [1, 2, 3, 4].every((q) => plottedParetoQuadrants[q]);

  const toggleAllQuadrants = () => {
    const newValue = !allSelected;
    ReactGA.event("toggle_pareto");
    handleToggleQuadrant(newValue); // Using the new function to update all quadrants at once
  };

  useEffect(() => {
    if (!allSelected && [1, 2, 3, 4].some((q) => plottedParetoQuadrants[q])) {
      toggleAllQuadrants();
    }
  }, []); // Dependency array remains empty to run only once on mount

  return (
    <div className="border-b-2 border-black">
      <div className="px-8 pt-4">
        <p className="text-lg font-semibold">Pareto Front</p>
      </div>
      <div className="px-8 pt-4 flex justify-start items-center space-x-4 pb-4">
        <label
          className={`w-12 h-12 flex items-center justify-center cursor-pointer border-2 border-black
                        ${
                          allSelected
                            ? "bg-berkeley-blue text-white hover:bg-berkeley-blue-200"
                            : "hover:bg-gray-200"
                        }`}
          onClick={toggleAllQuadrants}
        >
          <FontAwesomeIcon
            icon={faCheck}
            className={`text-lg ${
              allSelected ? "text-white" : "text-berkeley-blue-500"
            }`}
          />
        </label>
        {/* {[1, 2, 3, 4].map((quadrant) => (
                    <label key={quadrant} className={`w-12 h-12 flex items-center justify-center cursor-pointer hover:bg-gray-200
                        ${quadrants[quadrant] ? 'bg-berkeley-blue text-white' : 'border-2 border-black'}`}>
                        <span className="text-lg">{quadrant}</span>
                        <input 
                            type="checkbox"
                            className="hidden"
                            checked={quadrants[quadrant]}
                            onChange={() => handleToggleQuadrant(quadrant)}
                        />
                    </label>
                ))} */}
      </div>
    </div>
  );
}
