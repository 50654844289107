import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faGripVertical,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import type { Identifier, XYCoord } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";
import ReactGA from "react-ga4";

import PlotTrace from "./Trace";
import DragItemTypes from "./dragItemType";
import { RequiredTrace } from "../../../../utils/types";
import { DropLine, DropLinePosition } from "./TraceSettings";
import Tooltip from "../../../Tooltip";

export interface TraceItemProps {
  id: string;
  trace: RequiredTrace;
  traceCount: number;
  index: number;
  dropLine?: DropLine;
  setDropLine: React.Dispatch<React.SetStateAction<DropLine | undefined>>;
  moveTrace: (startIndex: number, endIndex: number) => void;
  toggleTraceVisibility: (trace: RequiredTrace, visible: boolean) => void;
  handleRemoveTrace: (trace: RequiredTrace) => void;
}

export interface DragItem {
  index: number;
  id: string;
  type: string;
}

export default function TraceItem({
  id,
  trace,
  traceCount,
  index,
  dropLine,
  setDropLine,
  moveTrace,
  toggleTraceVisibility,
  handleRemoveTrace,
}: TraceItemProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DragItemTypes.TRACE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const startIndex = item.index;
      const endIndex = index;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Moving down
      if (startIndex < endIndex && hoverClientY <= hoverMiddleY) {
        setDropLine({
          lineIndex: endIndex,
          itemDestinationIndex: endIndex - 1,
          position: DropLinePosition.Above,
        });
      } else if (startIndex < endIndex && hoverClientY > hoverMiddleY) {
        setDropLine({
          lineIndex: endIndex,
          itemDestinationIndex: endIndex,
          position: DropLinePosition.Below,
        });
      }
      // Moving up
      else if (startIndex > endIndex && hoverClientY < hoverMiddleY) {
        setDropLine({
          lineIndex: endIndex,
          itemDestinationIndex: endIndex,
          position: DropLinePosition.Above,
        });
      } else if (startIndex > endIndex && hoverClientY >= hoverMiddleY) {
        setDropLine({
          lineIndex: endIndex,
          itemDestinationIndex: endIndex + 1,
          position: DropLinePosition.Below,
        });
      }
      // In place
      else if (startIndex === endIndex && hoverClientY <= hoverMiddleY) {
        setDropLine({
          lineIndex: endIndex,
          itemDestinationIndex: endIndex,
          position: DropLinePosition.Above,
        });
      } else if (startIndex === endIndex && hoverClientY > hoverMiddleY) {
        setDropLine({
          lineIndex: endIndex,
          itemDestinationIndex: endIndex,
          position: DropLinePosition.Below,
        });
      }
    },
    drop(item, _) {
      const startIndex = item.index;
      const endIndex = Math.max(
        0,
        Math.min(dropLine!.itemDestinationIndex, traceCount - 1)
      );
      setDropLine(undefined);
      if (startIndex === endIndex) return;
      moveTrace(startIndex, endIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DragItemTypes.TRACE,
    item() {
      return { id, index };
    },
    collect(monitor) {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end(item, monitor) {
      // If the user dragged the item out of bounds, move it to the last set
      // drop index.
      if (!monitor.didDrop() && dropLine) {
        const startIndex = item.index;
        const endIndex = Math.max(
          0,
          Math.min(dropLine.itemDestinationIndex, traceCount - 1)
        );
        setDropLine(undefined);
        if (startIndex === endIndex) return;
        moveTrace(startIndex, endIndex);
      }
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={`relative ${isDragging ? "opacity-50" : "opacity-100"}`}
      data-handler-id={handlerId}
    >
      <button className="absolute left-0 -ml-7 w-6 h-8 flex items-center justify-center hover:bg-gray-200">
        <Tooltip text="Reorder">
          <span className="p-2">
            <FontAwesomeIcon
              icon={faGripVertical}
              className="text-sm text-gray-400"
            />
          </span>
        </Tooltip>
      </button>
      <div className="flex justify-between items-center space-x-2">
        <PlotTrace trace={trace} />
        <Tooltip text={trace.visible ? "Hide" : "Show"}>
          <button
            className={`w-8 h-8 flex items-center justify-center p-2 border-2 ${
              trace.visible ? "border-black" : "border-gray-400"
            } hover:bg-gray-200`}
            onClick={() => {
              ReactGA.event("toggle_trace_visibility");
              toggleTraceVisibility(trace, !trace.visible);
            }}
          >
            <FontAwesomeIcon
              icon={trace.visible ? faEye : faEyeSlash}
              className={`text-sm ${
                trace.visible ? "text-black" : "text-gray-400"
              }`}
            />
          </button>
        </Tooltip>
        <Tooltip text="Remove">
          <button
            className="w-8 h-8 flex items-center justify-center p-2 border-2 border-black hover:bg-gray-200"
            onClick={() => {
              ReactGA.event("remove_trace");
              handleRemoveTrace(trace);
            }}
          >
            <FontAwesomeIcon icon={faMinus} className="text-sm" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
}
