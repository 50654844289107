import { faX, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface AlertProps {
  message: string;
  onClose: () => void;
}

export default function Alert({ message, onClose }: AlertProps) {
  return (
    <div className="flex items-center justify-between p-2 border-2 border-black" role="alert">
      <span className="flex items-center">
        <FontAwesomeIcon icon={faInfoCircle} className="text-base px-2" />
        <div className="text-base">{message}</div>
      </span>
      <button
        className="flex items-center justify-center w-8 h-8 p-2 border-2 border-black hover:bg-gray-200"
        onClick={onClose}
      >
        <FontAwesomeIcon icon={faX} className="text-xs" />
      </button>
    </div>
  );
}
