import React from "react";
import { areFiltersEqual } from "../../../../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import FilterItem from "./FilterItem";
import { plottedFiltersAtom } from "../../../../common/store";
import { useAtom } from "jotai";
import Tooltip from "../../../Tooltip";
import ReactGA from "react-ga4";

export default function FilterSettings() {
  const [plottedFilters, setPlottedFilters] = useAtom(plottedFiltersAtom);
  return (
    <div className="border-b-2 border-black">
      <div className="px-8 pt-4">
        <p className="text-lg font-semibold">Filters</p>
      </div>
      <div className="px-8 pt-4">
        {plottedFilters.map((filter, i) => {
          return (
            <div className="flex justify-between item-center w-full pb-4 space-x-2">
              <FilterItem filter={filter} />
              <Tooltip text="Remove">
                <button
                  className="w-8 h-8 flex items-center justify-center p-2 border-2 border-black hover:bg-gray-200"
                  onClick={() => {
                    ReactGA.event("remove_filter");
                    setPlottedFilters((prev) =>
                      prev.filter((f) => !areFiltersEqual(f, filter))
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} className="text-sm" />
                </button>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
}
