import React, { ReactNode, useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";

interface TooltipProps {
  text: string | undefined;
  children: ReactNode;
}

const SHOW_TOOLTIP_AFTER = 500; // ms

export default function Tooltip({ text, children }: TooltipProps) {
  const targetRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (showTooltip && targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top + window.scrollY - 40,
        left: rect.left + window.scrollX + rect.width / 2,
      });
    }
  }, [showTooltip]);

  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(true);
    }, SHOW_TOOLTIP_AFTER);
  };

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowTooltip(false);
  };

  const tooltipElement = showTooltip
    ? ReactDOM.createPortal(
        <div
          className="fixed shadow-lg bg-white text-black border-2 border-black px-2 py-1 text-sm z-50"
          style={{
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            transform: "translateX(-50%)", // Centers the tooltip
          }}
        >
          {text}
        </div>,
        document.body
      )
    : null;

  return (
    <div
      ref={targetRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative"
    >
      {children}
      {tooltipElement}
    </div>
  );
}
