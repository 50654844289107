import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

export const OutlierTooltip = () => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div className="flex justify-start items-center">
      <button
        className="w-8 h-8 flex items-center justify-center p-2 border-2 border-gray-800 hover:bg-gray-200"
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <FontAwesomeIcon
          icon={faInfo}
          className="text-gray-800 text-sm cursor-pointer"
        />
      </button>
      {isTooltipVisible ? (
        <div className="pl-2">
          <div className="py-1 px-2 border-2 border-gray-800 text-sm text-gray-800 shadow-lg">
            Since this data is aggregated from third-party sources, certain
            plots may contain incorrect or invalid data (i.e. outliers).
          </div>
        </div>
      ) : null}
    </div>
  );
};
