import React, { useCallback, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ReactGA from "react-ga4";

import { RequiredTrace } from "../../../../utils/types";
import TraceItem from "./TraceItem";
import { useAtom } from "jotai";
import { plottedTracesAtom } from "../../../../common/store";

interface TraceSettingsProps {
  handleRemoveTrace: (trace: RequiredTrace) => void;
}

export enum DropLinePosition {
  Above,
  Below,
}

export type DropLine = {
  lineIndex: number;
  itemDestinationIndex: number;
  position: DropLinePosition;
};

const DROP_LINE_COLOR = "#000000";

export default function TraceSettings({
  handleRemoveTrace,
}: TraceSettingsProps) {
  const [plottedTraces, setPlottedTraces] = useAtom(plottedTracesAtom);
  const [dropLine, setDropLine] = useState<DropLine | undefined>();

  const moveTrace = useCallback(
    (startIndex: number, endIndex: number) => {
      ReactGA.event("move_trace");
      setPlottedTraces((prevTraces: RequiredTrace[]) => {
        console.log("from, to", startIndex, endIndex);
        let newTraces = [...prevTraces];
        newTraces.splice(endIndex, 0, newTraces.splice(startIndex, 1)[0]);
        return newTraces;
      });
    },
    [setPlottedTraces]
  );

  const toggleTraceVisibility = (trace: RequiredTrace, visible: boolean) => {
    const updatedTraces = plottedTraces.map((t) =>
      t.name === trace.name && t.year === trace.year ? { ...trace, visible } : t
    );
    setPlottedTraces(updatedTraces);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="border-b-2 border-black">
        <div className="px-8 pt-4">
          <p className="text-lg font-semibold">Traces</p>
        </div>
        <div className="px-8 py-2 relative">
          {plottedTraces.map((trace, index) => (
            <div className="relative py-2" key={`${trace.name}-${trace.year}`}>
              {dropLine?.lineIndex === index &&
                dropLine?.position === DropLinePosition.Above && (
                  <div className="absolute top-0 left-0 right-0 -mt-0.5">
                    <div
                      className="h-1 w-full"
                      style={{ backgroundColor: DROP_LINE_COLOR }}
                    />
                  </div>
                )}
              <TraceItem
                id={`${trace.name}-${trace.year}`}
                index={index}
                trace={trace}
                traceCount={plottedTraces.length}
                dropLine={dropLine}
                setDropLine={setDropLine}
                moveTrace={moveTrace}
                toggleTraceVisibility={toggleTraceVisibility}
                handleRemoveTrace={handleRemoveTrace}
              />
              {dropLine?.lineIndex === index &&
                dropLine?.position === DropLinePosition.Below && (
                  <div className="absolute bottom-0 left-0 right-0 -mb-0.5">
                    <div
                      className="h-1 w-full"
                      style={{ backgroundColor: DROP_LINE_COLOR }}
                    />
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
    </DndProvider>
  );
}
