import { atom } from "jotai";
import {
  PartialAxis,
  PartialFilter,
  PartialTrace,
  Point,
  RequiredAxis,
  RequiredFilter,
  RequiredTrace,
} from "../utils/types";
import { SelectionConstants } from "../interface/plot/consts";

// Selected Parameters
export const selectedAxesAtom = atom<PartialAxis[]>([]);
export const selectedTracesAtom = atom<PartialTrace[]>(
  SelectionConstants.selectedTraces
);
export const selectedFiltersAtom = atom<PartialFilter[]>(
  SelectionConstants.selectedFilters
);

// Plotted Parameters
export const plottedAxesAtom = atom<RequiredAxis[]>([]);
export const plottedTracesAtom = atom<RequiredTrace[]>([]);
export const plottedPointsWithXAtom = atom<Point[]>([]);
export const plottedFiltersAtom = atom<RequiredFilter[]>([]);
export const plottedParetoQuadrantsAtom = atom<{
  [key: number]: boolean;
}>(SelectionConstants.paretoQuadrants);
