export enum AxisKind {
  X = "x",
  Y = "y",
}

type Axis = {
  name?: string;
  abbreviation?: string;
  kind?: AxisKind;
};

export type PartialAxis = Partial<Axis>;
export type RequiredAxis = Required<Axis>;

type TraceBase = {
  name?: string;
  abbreviation?: string;
  color?: string;
  year?: string;
  visible?: boolean;
};

export type PartialTrace = Partial<TraceBase>;
export type RequiredTrace = Required<TraceBase>;

export function areTracesEqual(
  lhs: RequiredTrace,
  rhs: RequiredTrace
): boolean {
  return (
    lhs.name === rhs.name &&
    lhs.abbreviation === rhs.abbreviation &&
    lhs.color === rhs.color &&
    lhs.year === rhs.year &&
    lhs.visible === rhs.visible
  );
}

export type Point = {
  ptIdx: number;
  fullDataIdx: number;
  mpn: string;
  color: string;
  manufacturer: string;
  link: string;
  xAxis?: number;
  yAxis?: number;
  xUnits?: string;
  yUnits?: string;
};

export enum FilterKind {
  Numerical = "Numerical",
  Categorical = "Categorical",
}

type FilterBase = {
  kind?: FilterKind;
  name?: string;
  abbreviation?: string;
  id?: string;
  min?: number;
  minHumanString?: string;
  max?: number;
  maxHumanString?: string;
};

export type PartialFilter = Partial<FilterBase>;
export type RequiredFilter = Required<FilterBase>;

export function areFiltersEqual(
  lhs: RequiredFilter,
  rhs: RequiredFilter
): boolean {
  return (
    lhs.kind === rhs.kind &&
    lhs.name === rhs.name &&
    lhs.abbreviation === rhs.abbreviation &&
    lhs.min === rhs.min &&
    lhs.max === rhs.max &&
    lhs.id === rhs.id
  );
}
