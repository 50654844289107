import React from "react";
import { Point } from "../../../../utils/types";

interface PointItemProps {
  point: Point;
}

export default function PointItem({ point }: PointItemProps) {
  return (
    <div className="text-left border-2 border-black p-1 flex flex-wrap w-5/6">
      <div className="flex px-2 pt-1 items-center">
        <div
          className="self-start align-top"
          style={{
            height: "1rem",
            width: "1rem",
            backgroundColor: point.color,
          }}
        ></div>
      </div>
      <div className="flex flex-col">
        <span className="text-base pr-4">{point.mpn}</span>
        <span className="text-sm text-blue-500 flex items-center">
          {point.manufacturer}
        </span>
      </div>
    </div>
  );
}
