import { Component } from "../../proto/ts/componet.graph";

export const getAxisRange = (components: Component[], axisIndex: number) => {
  return components.reduce(
    (range, component) => {
      return {
        min: Math.min(range.min, component.axes?.[axisIndex].min),
        max: Math.max(range.max, component.axes?.[axisIndex].max),
      };
    },
    { min: Number.MAX_VALUE, max: Number.MIN_VALUE }
  );
};
