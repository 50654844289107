import React from "react";
import { RequiredFilter } from "../../../../utils/types";

interface FilterItemProps {
  filter: RequiredFilter;
}

export default function FilterItem({ filter }: FilterItemProps) {
  const humanString = (num: number) => {
    const epsilon = Number.EPSILON;

    // If num is either min or max, report it as +-Inf, otherwise represent it
    // in scientific notation.
    if (Math.abs(num - Number.MAX_SAFE_INTEGER) < epsilon) {
      return "+Inf";
    } else if (Math.abs(num - Number.MIN_SAFE_INTEGER) < epsilon) {
      return "-Inf";
    } else if (Math.abs(num) < epsilon) {
      return "0";
    } else if (Math.abs(num - 1) < epsilon) {
      return "1";
    } else if (Math.abs(num + 1) < epsilon) {
      return "-1";
    } else {
      const formattedNumber = Number(num.toFixed(1));
      return formattedNumber.toExponential().toString();
    }
  };

  return (
    <>
      <div className="flex h-8 w-full min-w-0 items-center text-left border-2 border-black p-1">
        <span className="text-base px-2 truncate">{filter.name}</span>
      </div>
      <div className="flex h-8 items-center text-left border-2 border-black">
        <span className="flex items-center justify-center border-r-2 border-black h-full px-2 text-xs text-gray-400">
          MIN
        </span>
        <span className="text-base px-2 truncate">
          {humanString(filter.min)}
        </span>
      </div>
      <div className="flex h-8 items-center text-left border-2 border-black">
        <span className="flex items-center justify-center border-r-2 border-black h-full px-2 text-xs text-gray-400">
          MAX
        </span>
        <span className="text-base px-2 truncate">
          {humanString(filter.max)}
        </span>
      </div>
    </>
  );
}
