import React from "react";
import { RequiredTrace } from "../../../../utils/types";

interface TraceProps {
  trace: RequiredTrace;
}

export default function PlotTrace({ trace }: TraceProps) {
  return (
    <div className="flex w-full min-w-0 h-8 items-center border-2 border-black">
      <div className="px-2">
        <div
          className="h-4 w-4"
          style={{
            backgroundColor: trace.color,
          }}
        ></div>
      </div>
      <span className="text-base pr-4 py-1 truncate">
        {trace.abbreviation} [{trace.year}]
      </span>
    </div>
  );
}
