import { NavLink } from "react-router-dom";
import React from "react";

export default function Navbar() {
  return (
    <div className="fixed top-0 left-0 w-full z-10">
      <div className="h-24 border-b-2 border-black flex justify-between bg-white pr-4">
        <div className="flex text-black m-4">
          <NavLink to="/">
            <img
              src="/componet-logo.png"
              alt="CompoNet Logo"
              className="h-16 w-auto object-contain" 
            />
          </NavLink>
        </div>
        <ul className="flex items-center space-x-4 text-sm pr-4">
          {/* <li>
            <NavLink to="/about" className="text-black text-base font-bold hover:text-gray-400">About</NavLink>
          </li> */}
          <li>
            <a
              href="https://forms.gle/gfTvfQfvWVsGenBE7"
              target="_blank"
              rel="noreferrer"
              className="text-black text-base font-bold hover:text-gray-400"
            >
              Feedback
            </a>
          </li>
          <li>
            <NavLink to="/contact" className="text-black text-base font-bold hover:text-gray-400">Contact</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
