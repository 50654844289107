import React from "react";
import componetIcon from "../assets/componet-icon.png";

interface PlotInfoProps {
  components: any[];
}

export default function PlotInfo({ components }: PlotInfoProps) {
  return (
    <div
      className="flex items-center justify-between p-2 border-2 border-black"
      role="alert"
    >
      <span className="flex items-center">
        <span className="px-2">
          <img src={componetIcon} alt={"compo net logo"} className="w-6 h-6" />
        </span>
        <div className="text-base">
          Plotting{" "}
          <b>
            {components
              ?.map((component) => component.x.length)
              .reduce((a, b) => a + b, 0)
              .toLocaleString("en-US")}{" "}
            components
          </b>{" "}
          from{" "}
          <b>
            {" "}
            {
              new Set(
                components.flatMap(
                  (component) => component.metadata.manufacturers
                )
              ).size
            }{" "}
            manufacturers
          </b>
          .
        </div>
      </span>
    </div>
  );
}
